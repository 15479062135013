import { Navigate, useLocation } from "react-router-dom";
import { useAuthStore } from "../store";

export const PrivateRoutes = ({ comp }) => {
  const loggedIn = useAuthStore(state => state.loggedIn)
  const location = useLocation()

  if (!loggedIn) return <Navigate to="/" replace state={{ path: location.pathname }} />
  return comp
}
