import cn from 'classnames';

function Loader({ wrapperCls = '', loaderCls = '' }) {
  return (
    <div className={cn("dc", wrapperCls)}>
      <div className={cn("loader", loaderCls)}></div>
    </div>
  )
}

export default Loader
