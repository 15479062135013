import create from "zustand";
import { devtools } from 'zustand/middleware';
import { assignCartIdToMobile } from "../actions/cart";

export const useAuthStore = create(devtools((set) => ({
  loggedIn: false,
  logIn: () => {
    let planInLocal = localStorage.getItem("plan")
    let savedCartId = planInLocal ? JSON.parse(planInLocal)?.state?.savedCartId : ""
    if (savedCartId) {
      assignCartIdToMobile(savedCartId)
    }
    set({ loggedIn: true })
  },
  logOut: () => set({ loggedIn: false }),
}), { name: "auth" }))
