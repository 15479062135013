import { resendOtpToUpdatePhone, updateNewUserPhone } from "../../actions/auth";
import OtpUi from './OtpUi';

function EnterOtp2({ phoneNum, logIn, closeModal }) {
  const onSubmit = (otp, setIsLoading) => {
    const onSuccess = () => {
      logIn()
      closeModal()
    }
    updateNewUserPhone(otp, onSuccess, () => setIsLoading(false))
  }

  const resendOtp = () => resendOtpToUpdatePhone(phoneNum)

  return (
    <OtpUi
      phoneNum={phoneNum}
      onSubmit={onSubmit}
      resendOtp={resendOtp}
    />
  )
}

export default EnterOtp2
