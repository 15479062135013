import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import cn from 'classnames';

function Modal({
  isOpen = true, closeModal = () => { }, children,
  overlayCls = "", wrapperCls = "", contentWrapperCls = "",
  contentCls = "", contentStyle = {},
}) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`modal-wrapper ${wrapperCls}`}
        onClose={closeModal}
      >
        <div className="min-h-screen">
          <div className={`modal-overlay ${overlayCls}`}></div>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95 origin-center"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className={cn("modal-content-wrapper", contentWrapperCls)}>
              <Dialog.Panel
                className={cn("modal-content", contentCls)}
                style={contentStyle}
              >
                {children}
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
