import sendApiReq from "../utils/sendApiReq";
import endPoints from "../utils/endPoints";
import { auth } from "../firebase";

export function checkEmailAlreadyRegistered(email) {
  return sendApiReq({
    url: endPoints.checkUserRegisteredByEmail + email + "/registered",
  });
}

export function checkPhoneAlreadyRegistered(phone) {
  return sendApiReq({
    url: endPoints.checkUserRegisteredByMobile + phone + "/registered",
  });
}

export function getUserByEmail() {
  if (auth?.currentUser?.email) {
    return sendApiReq({
      url: endPoints.getUserByEmail + auth.currentUser.email,
    });
  }
  return {};
}

export function getUserByPhone() {
  return sendApiReq({
    url: endPoints.getUserByMobileNo + auth.currentUser.phoneNumber,
  });
}

export async function updateUserProfile(data) {
  return sendApiReq({
    url: endPoints.updateUserProfile + data.id,
    successMsg: "User profile updated successfully",
    method: "put",
    data,
  });
}

export async function updateProfilePic(data) {
  return sendApiReq({
    url:
      endPoints.updateProfilePic +
      data.mobile +
      `?profilePicURL=${data.profilePicURL}`,
    successMsg: "User profile pic updated successfully",
    method: "put",
  });
}

export async function deleteProfilepic(data) {
  return sendApiReq({
    url: endPoints.updateProfilePic + data.mobile,
    successMsg: "User profile pic deleted successfully",
    method: "pdelete",
  });
}

export const updateLanguifyUrl = async (url) => {
  return sendApiReq({
    url: `${endPoints.updateLanguifyUrl}${auth.currentUser.phoneNumber}/{userLanguifyId}?userLanguifyId=${url}`,
    successMsg: "Languify Url updated successfully",
    method: "put",
  });
};
