import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { signOut } from 'firebase/auth';

import { useAuthStore, usePlanStore } from '../../../store';
import { getUserByEmail } from '../../../actions/user';
import { auth } from '../../../firebase';

import { ReactComponent as Arrow } from "../../../assets/svg/arrows/chevron-left.svg";
import DropDown from "../../UIComp/DropDown";

const list = [
  "My Courses",
  "My Dashboard",
  "My Bookmarks",
  "My Profile",
  "Payment History",
  {
    key: "logout",
    Comp: <button className="w-32 h-11 bg-primary-600 text-white hover:bg-primary-500">Logout</button>
  }
]

function ProfileWrapper({ open }) {
  const { isLoading, data } = useQuery({
    queryFn: getUserByEmail,
    queryKey: ["user-details"],
  })

  const userName = auth?.currentUser?.displayName

  return (
    <>
      <span className="dc w-11 h-11 uppercase text-link rounded-full text-white bg-primary-600">
        {
          !isLoading && (
            data?.profilePicURL
              ? <img src={data?.profilePicURL} alt="profile" className='w-11 h-11 object-cover rounded-full' />
              : <>{userName?.[0]}</>
          )
        }
      </span>

      <Arrow className={`w-[14px] h-[14px] transition-transform ${open ? "rotate-90" : "-rotate-90"}`} />
    </>
  )
}

function Profile() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const clearCart = usePlanStore(state => state.clear)
  const logOut = useAuthStore(state => state.logOut)

  const onClk = val => {
    let to = {
      "My Profile": "/profile",
      "My Courses": "/my-courses",
      "My Bookmarks": "/bookmark",
      "My Dashboard": "/student-dashboard",
      "Payment History": "/payment-history",
    }
    if (to[val]) {
      navigate(to[val])
    } else {
      signOut(auth).then(r => {
        logOut()
        clearCart()
        queryClient.clear()
        window.location.replace('/')
      })
    }
  }

  return (
    <DropDown
      root={({ open }) => <ProfileWrapper open={open} />}
      list={list}
      btnCls="p-0 gap-1.5 shrink-0"
      onClick={onClk}
      optionCls="py-3 px-5"
      activeCls="text-primary-600"
    />
  )
}

export default Profile
