import {
  RecaptchaVerifier, signInWithPhoneNumber,
  GoogleAuthProvider, signInWithPopup,
  PhoneAuthProvider, updatePhoneNumber,
  updateProfile, updateEmail,
  // deleteUser
} from "firebase/auth";

import { checkEmailAlreadyRegistered, checkPhoneAlreadyRegistered } from './user';
import { errorNotify } from "../helper/toastifyHelp";
import sendApiReq from "../utils/sendApiReq";
import endPoints from "../utils/endPoints";
import { auth } from "../firebase";
import devLog from "../utils/devLog";

export async function createUser(data, onSuccess, onError) {
  try {
    await sendApiReq({
      method: "post",
      url: endPoints.saveUser,
      headers: { uid: data.id },
      data: {
        fname: data.firstName,
        lname: data.lastName,
        mobile: data.mobile,
        emailId: data.email,
        profilePicURL: data?.pic || "",
        skillAuraCreditAccumulated: 0,
        purchaseHistoryList: [],
        creditsUtilised: 0,
        referredBy: "",
        address: "",
        college: "",
        branch: "",
        gender: "",
        roles: ["STUDENT"],
        dob: "",
      },
    })

    onSuccess()
  } catch (error) {
    devLog(error)
    onError()
    // deleteUser(auth.currentUser)
    errorNotify("Cannot update user details")
  }
}

const onRecaptcha = () => {
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': () => { },
      'expired-callback': () => { },
    }, auth)
  }
}

export async function onLoginViaPhone(phoneNumber, onSuccess, onError) {
  try {
    onRecaptcha()
    const appVerifier = window.recaptchaVerifier

    const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    window.confirmationResult = confirmationResult
    devLog("signInWithPhoneNumber", auth.currentUser)
    onSuccess()

  } catch (error) {
    devLog(error)
    onError()
    if (error?.code === "auth/invalid-phone-number") {
      errorNotify("Invalid phone number")
    } else {
      errorNotify()
    }
  }
}

export async function enterOtpViaPhone(otp, onSuccess, onErro) {
  try {
    await window.confirmationResult.confirm(otp)
    const isRegistered = await checkPhoneAlreadyRegistered(auth.currentUser.phoneNumber)
    onSuccess(isRegistered)

  } catch (error) {
    devLog(error)
    onErro()
    if (error?.code === "auth/invalid-verification-code") {
      errorNotify("Invalid verification code")
    } else {
      errorNotify()
    }
  }
}

export async function resendOtpToLogin(phoneNumber) {
  try {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier('reset-button', {
        'size': 'invisible',
        'callback': () => { },
        'expired-callback': () => { },
      }, auth)
    }

    const appVerifier = window.recaptchaVerifier

    const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    window.confirmationResult = confirmationResult

  } catch (error) {
    devLog(error)
    errorNotify()
  }
}

export async function updateNewUserMail(data, onSuccess, onError) {
  try {
    const isEmailAlreadyRegistered = await checkEmailAlreadyRegistered(data.email)
    if (isEmailAlreadyRegistered) {
      onError()
      return errorNotify("Email already registered")
    }

    updateEmail(auth.currentUser, data.email).then(() => {
      updateProfile(auth.currentUser, {
        displayName: `${data.firstName} ${data.lastName}`
      }).then(() => {
        createUser(
          {
            ...data,
            id: auth.currentUser.uid,
            mobile: auth.currentUser.phoneNumber,
            pic: auth.currentUser.photoURL,
          },
          onSuccess,
          onError
        )
      })
    })

  } catch (error) {
    devLog(error)
    onError()
    if (error?.code === "auth/email-already-in-use") {
      errorNotify("Email already in use")
    } else {
      errorNotify()
    }
  }
}

export async function onLoginViaGoogle(onSuccess) {
  try {
    const provider = new GoogleAuthProvider()
    const res = await signInWithPopup(auth, provider)
    const isRegistered = await checkEmailAlreadyRegistered(res.user.email)
    onSuccess(isRegistered)

  } catch (error) {
    devLog(error)
    errorNotify()
  }
}

const onRecaptchaOnLoginViaGoogle = () => {
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': () => { },
      'expired-callback': () => { },
    }, auth)
  }
}

export async function verifyPhoneOnLoginViaGoogle(phoneNumber, onSuccess, onError) {
  try {
    const isPhoneAlreadyRegistered = await checkPhoneAlreadyRegistered(phoneNumber)
    if (isPhoneAlreadyRegistered) {
      onError()
      return errorNotify("Mobile number already registered")
    }

    onRecaptchaOnLoginViaGoogle()

    const appVerifier = window.recaptchaVerifier
    const provider = new PhoneAuthProvider(auth)
    const res = await provider.verifyPhoneNumber(phoneNumber, appVerifier)
    window.verifyPhone = res

    onSuccess()

  } catch (error) {
    devLog(error)
    onError()
    errorNotify()
  }
}

export async function resendOtpToUpdatePhone(phoneNumber) {
  try {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier('reset-button', {
        'size': 'invisible',
        'callback': () => { },
        'expired-callback': () => { },
      }, auth)
    }

    const appVerifier = window.recaptchaVerifier
    const provider = new PhoneAuthProvider(auth)
    const res = await provider.verifyPhoneNumber(phoneNumber, appVerifier)
    window.verifyPhone = res

  } catch (error) {
    devLog(error)
    errorNotify()
  }
}

export async function updateNewUserPhone(otp, onSuccess, onError) {
  try {
    const phoneCredential = PhoneAuthProvider.credential(window.verifyPhone, otp)

    await updatePhoneNumber(auth.currentUser, phoneCredential)

    createUser(
      {
        id: auth.currentUser.uid,
        firstName: auth.currentUser.displayName.split(" ")[0] || "",
        lastName: auth.currentUser.displayName.split(" ")[1] || "",
        email: auth.currentUser.email,
        mobile: auth.currentUser.phoneNumber,
        pic: auth.currentUser.photoURL
      },
      onSuccess,
      onError
    )

  } catch (error) {
    devLog(error)
    onError()
    if (error?.code === "auth/invalid-verification-code") {
      errorNotify("Invalid verification code")
    } else {
      errorNotify()
    }
  }
}
