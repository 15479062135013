import create from "zustand";
import { devtools, persist } from 'zustand/middleware';

export const usePlanStore = create(devtools(persist((set) => ({
  tenure: 3,
  savedCartId: "",
  addedToCart: false,
  selectedPlanId: "",
  update: (key, val) => set({ [key]: val }),
  updateOnLogin: obj => set({ ...obj }), // will pass all data
  saveCart: cartId => set(st => ({
    addedToCart: true,
    savedCartId: cartId || st.savedCartId
  })),
  clear: () => set({
    tenure: 3,
    savedCartId: "",
    addedToCart: false,
    selectedPlanId: "",
  }),
}), { name: "plan" }), { name: "plan" }))
