import { Link } from "react-router-dom";

import { ReactComponent as Location } from "../../assets/svg/common/location.svg";
import { ReactComponent as Call } from "../../assets/svg/common/call.svg";
import { ReactComponent as Mail } from "../../assets/svg/common/mail.svg";
import SkillAuraLogo from "../../assets/svg/logo/Skillaura-Logo.svg";

function Footer() {
  return (
    <>
      <footer className="pad-5 text-c-xs sm:text-c-s text-black-600 border-t border-[#13131329]">
        <div className="grid xs:grid-cols-2 mdb:grid-cols-4 xl:grid-cols-5 justify-between gap-8">
          <div className="xs:col-span-2 xl:col-span-3">
            <img className="mb-5" src={SkillAuraLogo} alt="Skill Aura" />

            <p className="text-c-s max-w-sm">
              Skill Aura is a Digital Learning Platform that helps students land on their dream job. We do this by providing 100+ courses (Aptitude, Technical, Soft Skill and so on) - all that is required for a student to land a high paying job under one subscription.
            </p>
          </div>

          <div className="dfc gap-4">
            <strong className="text-h-xs text-primary-600">Useful links</strong>
            <Link to="/about-us">About us</Link>
            <Link to="/contact-us">Contact Us</Link>
            <Link to="/terms-and-condition">Terms & Condition</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>

          <div className="dfc gap-4 max-w-[250px]">
            <strong className="text-h-xs text-primary-600">Contact Us</strong>
            <div className="flex gap-3">
              <Location className="w-5 shrink-0" />
              <p># 363, 19th Main, 1st Block, Rajajinagar, Bangalore-560010</p>
            </div>

            <div className="flex gap-3">
              <Call className="w-5 shrink-0" />
              <p>+91 9743606869</p>
            </div>

            <div className="flex gap-3">
              <Mail className="w-5 shrink-0" />
              <p>contact@skillaura.com</p>
            </div>
          </div>
        </div>
      </footer>

      <div className="pad-5 py-6 md:py-10 md:mx-[5%] md:px-0 grid grid-cols-2 sm:flex gap-3 justify-center sm:gap-6 xl:gap-16 text-c-xs sm:text-c-s border-t border-[#13131329] text-[#1A202C] max-sm:text-center">
        <Link to="/privacy-policy">Privacy & Policy</Link>
        <Link to="/terms-and-condition">Terms & Condition</Link>
        <p className="col-span-2 sm:-order-1 sm:mr-auto">
          © Copyright 2023 Skill Aura Innovative Labs Pvt Ltd. All Rights Reserved.
        </p>
      </div>
    </>
  )
}

export default Footer
