import { useState } from 'react';
import { useForm } from "react-hook-form";

import CountryCode from "./CountryCode";
import Btn from "../UIComp/Btn";

function MobileNumUi({ submitTxt = "Sign in with OTP", onSubmit = () => { } }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dialCode, setDialCode] = useState("+91")
  const { register, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      mobileNumber: "",
    },
  })

  const onSubmitWrapper = data => {
    setIsLoading(true)
    const phoneNumber = dialCode + data.mobileNumber
    onSubmit(phoneNumber, setIsLoading)
  }

  return (
    <>
      <div className="mb-6 text-c-m">
        Please enter your mobile number
      </div>

      <form onSubmit={handleSubmit(onSubmitWrapper)}>
        <div className={errors?.mobileNumber ? "mb-1" : "mb-6"}>
          <label htmlFor="login-mobileNumber">
            Mobile Number
          </label>

          <div className={`df border rounded-md ${errors?.mobileNumber ? "border-red-600" : "border-black-200"}`}>
            <CountryCode onChange={setDialCode} />
            <input
              autoFocus
              id="login-mobileNumber"
              type="text"
              className="border-none flex-1"
              placeholder="Mobile Number"
              style={{ paddingLeft: 0 }}
              {...register("mobileNumber", {
                required: "Mobile number is required",
                minLength: { value: 6, message: "Please enter a valid Mobile number" },
                maxLength: { value: 12, message: "Please enter a valid Mobile number" },
              })}
            />
          </div>
        </div>

        {
          errors.mobileNumber &&
          <div className="mb-6 text-ui-s text-red-600">
            {errors.mobileNumber.message}
          </div>
        }

        <Btn
          isSecondary
          id="sign-in-button"
          type="submit"
          text={submitTxt}
          disabled={isLoading}
          className="w-full h-12"
        />
      </form>
    </>
  )
}

export default MobileNumUi
