export const tenureMonthtoText = {
  3: "THREEMONTHS",
  6: "SIXMONTHS",
  12: "TWELVEMONTHS",
  18: "EIGHTEENMONTHS",
  24: "TWENTYFOURMONTHS",
  36: "THIRTYSIXMONTHS",
}

export const tenureTextToMonth = {
  "THREEMONTHS": 3,
  "SIXMONTHS": 6,
  "TWELVEMONTHS": 12,
  "EIGHTEENMONTHS": 18,
  "TWENTYFOURMONTHS": 24,
  "THIRTYSIXMONTHS": 36,
}
