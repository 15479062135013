import { ReactComponent as Bell } from "../../../assets/svg/common/bell.svg";

function Notification() {
  return (
    <button className="p-0 relative shrink-0 max-mdb:hidden">
      <Bell className="w-5 h-5" />
      {/* <span className="absolute top-0 right-0 w-2 h-2 bg-red-600 rounded-full"></span> */}
    </button>
  )
}

export default Notification
