import { useState } from "react";
import { NavLink } from "react-router-dom";

import { useAuthStore } from "../../../store";

import { ReactComponent as Hamburger } from "../../../assets/svg/common/hamburger.svg";
import { ReactComponent as Close } from "../../../assets/svg/actions/close.svg";
import Logo from "../../../assets/svg/logo/Skillaura-Logo.svg";

import LoginWrapper from "../../Auth/LoginWrapper";
import Notification from "./Notification";
import Profile from "./Profile";
import Cart from "./Cart";

function Nav() {
  const loggedIn = useAuthStore(state => state.loggedIn)

  const [modal, setModal] = useState("")
  const [open, setOpen] = useState(false)

  const updateModal = () => setModal(p => !p)

  return (
    <nav className="df gap-7 h-[74px] px-[6%] py-2 sticky top-0 text-c-s shadow-[0_4px_4px_#0000001A] bg-white z-2">
      <a href="/" className="max-mdb:mr-auto">
        <img src={Logo} alt="Skill Aura" />
      </a>

      <div className={`df nav-links ${open ? "" : "max-mdb:hidden"}`}>
        <NavLink className={({ isActive }) => isActive ? "text-primary-600" : ""} to="/interview-preparation">Interview Preparation</NavLink>
        {/* <NavLink className={({ isActive }) => isActive ? "text-primary-600" : ""} to="/resources">Resources</NavLink> */}

        {
          loggedIn &&
          <NavLink className={({ isActive }) => `mr-4 ${isActive ? "text-primary-600" : ""}`} to="/my-courses">My courses</NavLink>
        }
      </div>

      {
        loggedIn &&
        <Notification />
      }

      <Cart loggedIn={loggedIn} />

      {
        loggedIn
          ? <Profile />
          :
          <button
            className="bg-primary-600 text-white hover:bg-white hover:text-primary-600 outline outline-primary-600 -outline-offset-1"
            onClick={updateModal}
          >
            Sign in
          </button>
      }

      <button
        className="p-0 mdb:hidden"
        onClick={() => setOpen(p => !p)}
      >
        {
          open
            ? <Close className="stroke-[#596780]" />
            : <Hamburger className="stroke-[#596780]" />
        }
      </button>

      {modal && <LoginWrapper closeModal={updateModal} />}
    </nav>
  )
}

export default Nav
