import cn from 'classnames';
import Arrow from "../../assets/arrow.svg";

function Btn({
  text = "",
  className = "",
  isSecondary = false,
  isTeritary = false,
  onClick = () => { },
  needArrow = true,
  ...rest
}) {
  return (
    <button
      className={cn("dc theme-btn", className, {
        "secondary-btn": isSecondary,
        "teritary-btn": isTeritary
      })}
      onClick={onClick}
      {...rest}
    >
      {text}

      {
        !isTeritary && needArrow &&
        <img className='theme-btn-img' src={Arrow} alt="" />
      }
    </button>
  )
}

export default Btn
