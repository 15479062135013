import { useEffect } from "react";
import { usermavenClient } from "@usermaven/sdk-js";
import { useLocation } from "react-router-dom";

export function usePageView() {
  const usermaven = usermavenClient({
    key: "UMSK1FUrAK",
    tracking_host: "https://events.usermaven.com",
  })

  const location = useLocation()

  useEffect(() => {
    usermaven?.track("pageview")
  }, [location, usermaven])

  return null
}
