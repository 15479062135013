import { enterOtpViaPhone, resendOtpToLogin } from '../../actions/auth';
import OtpUi from './OtpUi';

function EnterOtp({ phoneNum, setStep, closeModal }) {
  const onSubmit = (otp, setIsLoading) => {
    const onSuccess = isRegistered => {
      if (!isRegistered) setStep(3)
      else closeModal()
    }

    const onError = () => setIsLoading(false)

    enterOtpViaPhone(otp, onSuccess, onError)
  }

  const resendOtp = () => resendOtpToLogin(phoneNum)

  return (
    <>
      <OtpUi
        phoneNum={phoneNum}
        onSubmit={onSubmit}
        resendOtp={resendOtp}
      />

      <div className="mt-8 text-center text-c-xs text-[#8D8D8D]">
        Sign in another way
      </div>
    </>
  )
}

export default EnterOtp
