import { errorNotify, successNotify } from "../helper/toastifyHelp";
import { tenureMonthtoText } from '../constants/tenure';
import sendApiReq from "../utils/sendApiReq";
import endPoints from "../utils/endPoints";
import { auth } from "../firebase";
import devLog from "../utils/devLog";

export function getCartById(id) {
  return sendApiReq({
    isAuthendicated: false,
    url: endPoints.getCart + id,
  })
}

export function getCartByMobile() {
  return sendApiReq({
    url: endPoints.getCartByMobile + auth.currentUser.phoneNumber,
  })
}

export async function assignCartIdToMobile(cartId) {
  try {
    await sendApiReq({
      method: "put",
      url: endPoints.assignCartIdToMobile + cartId + "/" + auth.currentUser.phoneNumber,
    })

  } catch (error) {
    devLog(error)
  }
}

export async function addCartInBackend({ tenure, planData }, onSuccess, onError) {
  try {
    const choosedPlan = planData.planPricing.find(p => p.tenure === tenureMonthtoText[tenure])
    const totalCartPrice = choosedPlan.packageDiscount
    const mobileNo = auth?.currentUser?.phoneNumber || ""

    const data = {
      mobileNo,

      sgst: 0,
      cgst: 0,

      discount: 0,
      discountedPrice: 0,
      discountPercentage: 0,
      couponApplied: false,
      couponName: "",

      creditEquivalentCash: 0,
      creditsApplied: false,
      utilisedCredits: 0,

      totalCartPrice,
      finalPrice: totalCartPrice,

      packageDetail: {
        ...planData,
        planPricing: [choosedPlan],
      },
    }

    const cartId = await sendApiReq({
      isAuthendicated: false,
      method: "post",
      url: endPoints.addToCart,
      data,
    })

    onSuccess(cartId)
    successNotify("Plan added to cart successfully")

  } catch (error) {
    devLog(error)
    errorNotify()
    onError()
  }
}

export async function updateCartInBackend({ tenure, planData, oldData }, onSuccess) {
  try {
    const choosedPlan = planData.planPricing.find(p => p.tenure === tenureMonthtoText[tenure])
    const totalCartPrice = choosedPlan.packageDiscount

    const data = {
      ...oldData,

      totalCartPrice,
      finalPrice: totalCartPrice,

      packageDetail: {
        ...planData,
        planPricing: [choosedPlan],
      },
    }

    await sendApiReq({
      isAuthendicated: false,
      method: "put",
      url: endPoints.updateCart,
      data
    })

    onSuccess()
    successNotify("Cart updated successfully")

  } catch (error) {
    devLog(error)
    errorNotify()
  }
}

export async function deleteCartInBackend(id, onSuccess, needSuccessMsg) {
  try {
    await sendApiReq({
      isAuthendicated: false,
      method: "delete",
      url: endPoints.deleteCart + id
    })

    onSuccess()
    if (needSuccessMsg) {
      successNotify("Cart deleted successfully")
    }

  } catch (error) {
    devLog(error)
    errorNotify()
  }
}

export async function getCouponDetails(name, onSuccess, onError) {
  try {
    const res = await sendApiReq({
      isAuthendicated: false,
      url: endPoints.getCoupon + name
    })

    if (!res) {
      errorNotify("Coupon not valid")
      onError()

    } else if (res.active) {
      onSuccess(res.couponDiscountPercentage, res.couponMaxDiscount)
      successNotify("Coupon applied")

    } else if (new Date(res.expiryDate).getTime() < new Date().getTime()) {
      errorNotify("Coupon expired")
      onError()
    }

  } catch (error) {
    devLog(error)
    errorNotify()
    onError()
  }
}

export async function checkOut(payload) {
  try {
    const mobileNo = auth?.currentUser?.phoneNumber || ""

    const data = {
      id: payload.cartId,
      mobileNo,

      sgst: 0,
      cgst: 0,

      discount: 0,
      discountedPrice: payload.discountAmt,
      discountPercentage: payload.discountPercent,
      couponApplied: payload.isDiscountApplied,
      couponName: payload.discountCode,

      creditsApplied: payload.isCreditsApplied,
      utilisedCredits: payload.availableCredits,
      creditEquivalentCash: payload.creditsAmt,

      totalCartPrice: payload.purchasedPlanCost,
      finalPrice: payload.purchasedPlanCost,

      packageDetail: { ...payload },
    }

    await sendApiReq({
      method: "post",
      url: endPoints.createOrder + payload.orderId,
      data
    })

  } catch (error) {
    devLog(error)
  }
}

export function getPaymentHistory() {
  return sendApiReq({
    url: endPoints.getOrder + auth.currentUser.phoneNumber + "/subscription",
  })
}
