import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react-dom-interactions';
import cn from 'classnames';

function DropDown({
  root = null, strategy = 'absolute', placement = 'bottom-end',
  btnCls = "p-0", activeCls = "text-primary-600 bg-primary-100",
  wrapperCls = "", optionsBoxCls = "", optionCls = "text-ui-m",
  list = [], onClick = () => { },
}) {
  const { x, y, reference, floating, strategy: floatStrategy } = useFloating({
    placement,
    strategy,
    middleware: [flip(), shift(), offset(5)],
    whileElementsMounted: autoUpdate
  })

  return (
    <Menu as="div" className={cn("relative", wrapperCls)}>
      <div ref={reference} className="w-fit">
        <Menu.Button className={cn("df", btnCls)}>
          {root}
        </Menu.Button>
      </div>

      <Transition
        appear
        as={Fragment}
        enter="transition-opacity ease-out duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-in duration-75"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Menu.Items
          ref={floating}
          style={{
            position: floatStrategy,
            top: y ?? "",
            left: x ?? "",
          }}
          className={cn("dropdown-options", optionsBoxCls)}
        >
          {
            list.map(l => (
              <Menu.Item
                as="div"
                key={typeof l === "string" ? l : l.key}
                className={({ active }) => cn("dropdown-item", optionCls, l.className, {
                  [activeCls]: active
                })}
                onClick={() => onClick(typeof l === "string" ? l : l.key)}
              >
                {typeof l === "string" ? l : l.Comp}
              </Menu.Item>
            ))
          }
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default DropDown
