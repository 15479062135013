import { ReactComponent as Close } from '../../assets/svg/actions/close.svg';
import Logo from '../../assets/svg/logo/Skillaura-Logo.svg';
import Modal from '../UIComp/Modal';

function Template({ title = "", closeModal = () => { }, showClose = false, children }) {
  return (
    <Modal
      isOpen
      contentWrapperCls="auth-box"
      contentCls="max-w-[450px] relative"
    >
      {
        showClose &&
        <Close
          className="absolute top-6 right-6 hover:scale-110"
          onClick={closeModal}
        />
      }

      <strong className='df gap-2 text-c-m'>
        Welcome to
        <img
          className='h-10'
          src={Logo}
          alt="Skill Aura"
        />
      </strong>

      <h1 className='my-6 text-h-m'>{title}</h1>

      {children}
    </Modal>
  )
}

export default Template
