import { useEffect, useState } from "react";
import { onAuthStateChanged } from 'firebase/auth';
import { useLocation } from "react-router";

import { useAuthStore } from "./store/auth";
import { usePageView } from "./hooks/useUserMavean";
import { auth } from './firebase';

import { AppRoutes } from "./routes";
import ScrollToTop from "./comp/Common/ScrollToTop";
import Loader from "./comp/Common/Loader";
import Footer from './comp/Layout/Footer';
import Nav from "./comp/Layout/Nav";
import devLog from "./utils/devLog";

function App() {
  const [loaded, setLoaded] = useState(false)
  const { pathname } = useLocation()
  const logIn = useAuthStore(state => state.logIn)

  usePageView()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser?.email && currentUser?.phoneNumber) {
        logIn()
      }
      setLoaded(true)
    })

    return () => unsubscribe()
  }, [logIn])

  if (!loaded) return <Loader wrapperCls="h-screen" />

  return (
    <>
      <ScrollToTop />
      {
        pathname !== "/live-assessment" &&
        pathname !== "/achievement" &&
        pathname !== "/invoice" &&
        <Nav />
      }

      <AppRoutes />

      {
        pathname !== "/live-assessment" &&
        pathname !== "/achievement" &&
        pathname !== "/invoice" &&
        <Footer />
      }
    </>
  )
}

export default App;
