import React, { lazy, Suspense } from "react";
import { useRoutes } from "react-router-dom";

import { PrivateRoutes } from "./privateRoutes";
import Loader from "../comp/Common/Loader";

const InterviewPreparation = lazy(() => import("../comp/InterviewPreparation"));
const StudentRegisteration = lazy(() => import("../comp/StudentRegisteration"));
const CompanyDetails = lazy(() => import("../comp/CompanyDetails"));
const IPPlanDetails = lazy(() => import("../comp/IPPlanDetails"));
const IPBuyPlan = lazy(() => import("../comp/IPBuyPlan"));
const MockInterview = lazy(() => import("../comp/MockInterview/MockInterview"));
const ResourceInDetail = lazy(() => import("../comp/ResourceInDetail"));
const Resources = lazy(() => import("../comp/Resources"));


// const CourseContentLive = lazy(() => import("../comp/CourseContent/Live"));
// const CourseContentDemo = lazy(() => import("../comp/CourseContent/Demo"));
// const TechnicalCourse = lazy(() => import("../comp/TechnicalCourse"));
const LiveAssessment = lazy(() => import("../comp/LiveAssessment"));
const CourseDetails = lazy(() => import("../comp/CourseDetails"));
const MyCourse = lazy(() => import("../comp/MyCourse"));
// const IPCourse = lazy(() => import("../comp/IPCourse"));

const StudentDashboard = lazy(() => import("../comp/StudentDashboard"));
const PaymentHistory = lazy(() => import("../comp/PaymentHistory"));
const Bookmark = lazy(() => import("../comp/Bookmark"));
const Profile = lazy(() => import("../comp/Profile"));
const MyCart = lazy(() => import("../comp/MyCart"));

const TermAndCondition = lazy(() => import("../comp/TermsAndCondition"));
const PrivacyPolicy = lazy(() => import("../comp/PrivacyPolicy"));
const ContactUs = lazy(() => import("../comp/ContactUs"));
const AboutUs = lazy(() => import("../comp/AboutUs"));
const Home = lazy(() => import("../comp/Home"));
const Acheivement = lazy(() => import("../comp/Certificates/Acheivement"));
const Invoice = lazy(() => import("../comp/Certificates/Invoice"));

export const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/interview-preparation",
    element: <InterviewPreparation />,
  },
  {
    path: "/buy-plan",
    element: <IPBuyPlan />,
  },
  {
    path: "/plan-details",
    element: <IPPlanDetails />,
  },
  {
    path: "/company-details",
    element: <CompanyDetails />,
  },
  {
    path: "/student-registration",
    element: <StudentRegisteration />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  // {
  //   path: "/ip-courses",
  //   element: <IPCourse />,
  // },
  // {
  //   path: "/technical-courses",
  //   element: <TechnicalCourse />,
  // },
  // {
  //   path: "/course-content-live",
  //   element: <CourseContentLive />,
  // },
  // {
  //   path: "/course-content-demo",
  //   element: <CourseContentDemo />,
  // },
  {
    path: "/resources",
    element: <Resources />,
  },
  {
    path: "/resources/:id",
    element: <ResourceInDetail />,
  },
  {
    path: "/my-cart",
    element: <MyCart />,
  },
  {
    path: "/my-courses",
    element: <PrivateRoutes comp={<MyCourse />} />,
  },
  {
    path: "/course/:id",
    element: <PrivateRoutes comp={<CourseDetails />} />,
  },
  {
    path: "/live-assessment",
    element: <PrivateRoutes comp={<LiveAssessment />} />,
  },
  {
    path: "/payment-history",
    element: <PrivateRoutes comp={<PaymentHistory />} />,
  },
  {
    path: "/bookmark",
    element: <PrivateRoutes comp={<Bookmark />} />,
  },
  {
    path: "/student-dashboard",
    element: <PrivateRoutes comp={<StudentDashboard />} />,
  },
  {
    path: "/profile",
    element: <PrivateRoutes comp={<Profile />} />,
  },
  {
    path: "/terms-and-condition",
    element: <TermAndCondition />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/achievement",
    element: <Acheivement />,
  },
  {
    path: "/invoice",
    element: <Invoice />,
  },
  {
    path: "/mock-interview",
    element: <MockInterview />,
  },
];

export const AppRoutes = () => {
  const routeList = useRoutes(routes);

  return (
    <Suspense fallback={<Loader wrapperCls="h-96" />}>{routeList}</Suspense>
  );
};
