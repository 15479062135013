import { Fragment, useState } from 'react';
import { autoUpdate, flip, shift, useFloating } from '@floating-ui/react-dom-interactions';
import { Listbox, Transition } from '@headlessui/react';
import ReactCountryFlag from "react-country-flag";
import cn from 'classnames';

import countryCodes from '../../constants/countryCodes';

function CountryCode({
  strategy = "absolute", placement = 'bottom-end',
  onChange = () => { }
}) {
  const { x, y, reference, floating, strategy: floatStrategy } = useFloating({
    strategy,
    placement,
    middleware: [flip(), shift()],
    whileElementsMounted: autoUpdate
  })

  const [selected, setSelected] = useState({
    "name": "India",
    "flag": "🇮🇳",
    "code": "IN",
    "dial_code": "+91"
  })
  const [svgStyle] = useState({
    width: "20px",
    height: "20px"
  })

  const onUpdate = val => {
    setSelected(val)
    onChange(val)
  }

  return (
    <div className='relative'>
      <Listbox value={selected} onChange={onUpdate}>
        <div ref={reference}>
          <Listbox.Button className="df flag-btn">
            <ReactCountryFlag
              svg
              countryCode={selected.code}
              aria-label={selected.name}
              title={selected.name}
              style={svgStyle}
            />
            {selected.dial_code} -
          </Listbox.Button>
        </div>

        <Transition
          appear
          as={Fragment}
          enter="transition-opacity ease-out duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in duration-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            ref={floating}
            style={{
              position: floatStrategy,
              top: y ?? "",
              left: x ?? "",
            }}
            className="flag-options mini-scroll-bar"
          >
            {countryCodes.map((country, i) => (
              <Listbox.Option
                key={i}
                className={({ active }) => cn("flag-list", {
                  "bg-primary-200": active,
                })}
                value={country}
              >
                <span className="df truncate">
                  <ReactCountryFlag
                    svg
                    countryCode={country.code}
                    aria-label={country.name}
                    title={country.name}
                    style={svgStyle}
                  />
                  {country.dial_code}
                </span>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  )
}

export default CountryCode
