import { useState } from "react";
import { useAuthStore } from "../../store";
import ConfirmMobile from "./ConfirmMobile";
import ConfirmMail from "./ConfirmMail";
import EnterOtp2 from "./EnterOtp2";
import Template from "./Template";
import EnterOtp from "./EnterOtp";
import Login from "./Login";

function LoginWrapper({ closeModal }) {
  const logIn = useAuthStore(state => state.logIn)
  const [phoneNum, setPhoneNum] = useState("")
  const [step, setStep] = useState(1)

  const moveToOtp = (num) => {
    setPhoneNum(num)
    setStep(2)
  }

  const moveToOtp2 = num => {
    setPhoneNum(num)
    setStep(5)
  }

  return (
    <Template
      closeModal={closeModal}
      title={step < 3 ? "Sign in / Sign up" : "Update Your Details"}
      showClose={step < 2}
    >
      {
        step === 1 &&
        <Login
          setStep={setStep}
          moveToOtp={moveToOtp}
          closeModal={closeModal}
        />
      }

      {
        step === 2 &&
        <EnterOtp
          setStep={setStep}
          phoneNum={phoneNum}
          closeModal={closeModal}
        />
      }

      {
        step === 3 &&
        <ConfirmMail
          logIn={logIn}
          closeModal={closeModal}
        />
      }

      {
        step === 4 &&
        <ConfirmMobile
          setStep={setStep}
          moveToOtp2={moveToOtp2}
        />
      }

      {
        step === 5 &&
        <EnterOtp2
          logIn={logIn}
          closeModal={closeModal}
        />
      }
    </Template>
  )
}

export default LoginWrapper
