export const root = {
  baseUrl: process.env.REACT_APP_backend_url,
}

const endPoints = {
  saveUser: "/user/save",
  getUserByEmail: "/user/email/", // {email}
  getUserByMobileNo: "/user/mobile/", // {mobile}
  updateUserProfile: "/user/update-profile/", // {id}
  updateProfilePic: "/user/update/profile-pic/", // {mobile} ?profilePicURL
  checkUserRegisteredByEmail: "/user/email/", // {email}/registered
  checkUserRegisteredByMobile: "/user/mobile/", // {mobile}/registered
  updateLanguifyUrl: "/user/update/languify/", //{mobile/languifyId}

  connectWithUs: "/query/save",
  uploadFile: "/api/file",

  purchase: "/purchase/save/", // {txnId}

  getPackageDetails: "/package/", // {packageName}
  getAllPackage: "/package/all",

  getResourceById: "/resource/{id}?id=", // {id}
  getAllResources: "/resource/all",

  getCart: "/cart/", // {cartId}
  getCartByMobile: "/cart/mobile/", // {mobile}
  assignCartIdToMobile: "/cart/assign/", // {cartId}/{mobile}
  addToCart: "/cart/save",
  updateCart: "/cart/update",
  deleteCart: "/cart/", // {cartId}
  getCoupon: "/coupon/", // {couponName}
  createOrder: "/order/save/", // orderId

  getCompaniesByName: "/company/all/company-name",
  getAllCompanies: "/company/all",
  getCompanyById: "/company/", // {id}

  saveOrder: "/order/save", // {txnId}
  getOrder: "/order/user/", // {mobile}/subscription

  getCourseById: "/course/", // {id}
  getAllCourse: "/course/user/", // {mobile}
  updateWishList: "/course/", // {id}/wishlist/{value}
  updateBookmark: "/course/", // {id}/{chapterNo}/{slNo}/bookmark/{value}
  updateTopicStatus: "/course/", // {id}/{chapterNo}/{slNo}/topic-status/{value}
  updateCourseStatus: "/course/", // {id}/course-status/{value}
  saveQuiz: "/course/",// {id}/{chapterNo}/{assessmentId}/sbmit/
  getAssessmentById: "/assessment/",// {id}
  getReportById: "/assessment-result/",// {id}
  assessmentSave: "/assessment"
}

export default endPoints
