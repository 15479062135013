import axios from "axios";
import { errorNotify, successNotify } from "../helper/toastifyHelp";
import { auth } from '../firebase';
import { root } from './endPoints';

const requestIntercepter = (instance, isAuthendicated, headers) => {
  instance.interceptors.request.use(
    async function (config) {
      if (isAuthendicated) {
        const token = await auth.currentUser.getIdToken()

        config.headers = {
          Authorization: "Bearer " + token,
          ...headers
        }
      }

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  )
}

const responseIntercepter = (instance, successMsg, errorMsg) => {
  instance.interceptors.response.use(
    res => {
      if (successMsg) successNotify(successMsg)
      return res.data
    },
    error => {
      if (errorMsg) errorNotify(errorMsg)
      const err = new Error(error?.message)
      err.status = error?.response?.status
      err.message = error?.response?.data?.message
      throw err
    }
  )
}

const sendApiReq = ({ isAuthendicated = true, headers = {}, successMsg = "", errorMsg = "", ...others }) => {
  const instances = axios.create({ baseURL: root.baseUrl })
  requestIntercepter(instances, isAuthendicated, headers)
  responseIntercepter(instances, successMsg, errorMsg)
  return instances({ ...others })
}

export default sendApiReq;
