import { verifyPhoneOnLoginViaGoogle } from "../../actions/auth";
import MobileNumUi from './MobileNumUi';

function ConfirmMobile({ moveToOtp2 }) {
  const onSubmit = (phoneNumber, setIsLoading) => {
    verifyPhoneOnLoginViaGoogle(
      phoneNumber,
      () => moveToOtp2(phoneNumber),
      () => setIsLoading(false)
    )
  }

  return <MobileNumUi submitTxt="Proceed further" onSubmit={onSubmit} />
}

export default ConfirmMobile
