import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useEffect,useState } from "react";
import { tenureTextToMonth } from "../../../constants/tenure";
import { getCartByMobile } from "../../../actions/cart";
import { usePlanStore } from "../../../store";

import { ReactComponent as CartIc } from "../../../assets/svg/common/cart.svg";

function Cart({ loggedIn }) {
  const updateOnLogin = usePlanStore(st => st.updateOnLogin)
  const addedToCart = usePlanStore(st => st.addedToCart)
  const navigate = useNavigate()
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);
  useQuery({
    queryFn: getCartByMobile,
    queryKey: ["cart"],
    enabled: isMounted && !!loggedIn && !addedToCart,
    onSuccess(res) {
      if (res) {
        let payload = {
          tenure: tenureTextToMonth[res?.packageDetail?.planPricing?.[0]?.tenure],
          savedCartId: res?.id,
          addedToCart: true,
          selectedPlanId: res?.packageDetail?.id,
        }
        updateOnLogin(payload)
      }
    }
  })

  return (
    <button
      className="p-0 relative shrink-0 max-mdb:hidden"
      onClick={() => navigate("/my-cart")}
    >
      <CartIc className="w-5 h-5" />

      {
        addedToCart &&
        <span className="dc text-ui-s absolute -top-2 -right-2 w-4 h-4 bg-secondary-600 text-white rounded-full">
          1
        </span>
      }
    </button>
  )
}

export default Cart
