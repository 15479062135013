import { useState } from 'react';
import { useForm } from "react-hook-form";
import Btn from "../UIComp/Btn";

function OtpUi({ phoneNum, onSubmit, resendOtp }) {
  const [isLoading, setIsLoading] = useState(false)
  const { register, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      otp: "",
    },
  })

  const onSubmitWrapper = data => {
    setIsLoading(true)
    onSubmit(data.otp, setIsLoading)
  }

  const onReqResendOtp = e => {
    e.preventDefault()
    resendOtp()
  }

  return (
    <>
      <div className="my-4 text-c-m">
        Please enter the OTP you've received on <br />
        {phoneNum}
      </div>

      <form onSubmit={handleSubmit(onSubmitWrapper)}>
        <div className={`mt-6 ${errors.otp ? "mb-1" : "mb-3"}`}>
          <label htmlFor="otp">
            Enter OTP
          </label>

          <input
            autoFocus
            id='otp'
            type="text"
            {...register("otp", {
              required: "OTP is required",
              minLength: { value: 6, message: "Please enter a valid OTP" },
              maxLength: { value: 6, message: "Please enter a valid OTP" },
            })}
          />
        </div>

        {
          errors.otp &&
          <div className="mb-3 text-ui-s text-red-600">
            {errors.otp.message}
          </div>
        }

        <div className="df gap-0 mb-6 text-c-xs text-[#8D8D8D]">
          Didn't receive the OTP yet ?
          <button
            className="p-0 ml-1 text-c-xs text-primary-600 hover:underline"
            onClick={onReqResendOtp}
            id="reset-button"
            type="button"
          >
            Resend OTP
          </button>
        </div>

        <Btn
          isSecondary
          type="submit"
          text="Verify Mobile Number"
          disabled={isLoading}
          className="w-full h-12"
        />
      </form>
    </>
  )
}

export default OtpUi
