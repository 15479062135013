import { useState } from 'react';
import { useForm } from "react-hook-form";
import { updateNewUserMail } from '../../actions/auth';
import Btn from "../UIComp/Btn";

function ConfirmMail({ logIn, closeModal }) {
  const [isLoading, setIsLoading] = useState(false)
  const { register, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
  })

  const onSubmit = data => {
    setIsLoading(true)
    const onSuccess = () => {
      logIn()
      closeModal()
    }
    updateNewUserMail(data, onSuccess, () => setIsLoading(false))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-2 gap-6 mt-4">
        <div>
          <label htmlFor="signup-firstName">
            First Name
          </label>
          <input
            autoFocus
            id="signup-firstName"
            type="text"
            placeholder="First Name"
            {...register("firstName", {
              required: "First name is required"
            })}
          />

          {
            errors.firstName &&
            <div className="mt-0.5 text-ui-s text-red-600">
              {errors.firstName.message}
            </div>
          }
        </div>

        <div>
          <label htmlFor="signup-lastName">
            Last Name
          </label>
          <input
            id="signup-lastName"
            type="text"
            placeholder="Last Name"
            {...register("lastName", {
              required: "Last name is required"
            })}
          />
          {
            errors.lastName &&
            <div className="mt-0.5 text-ui-s text-red-600">
              {errors.lastName.message}
            </div>
          }
        </div>
      </div>

      <div className="my-6">
        <label htmlFor="signup-email">
          Email ID
        </label>

        <input
          id="signup-email"
          type="email"
          placeholder="Email ID"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^\S+@\S+$/i,
              message: "Enter a valid email"
            },
          })}
        />

        {
          errors.email &&
          <div className="mt-0.5 text-ui-s text-red-600">
            {errors.email.message}
          </div>
        }
      </div>

      <Btn
        isSecondary
        text="Proceed"
        className="w-full h-12"
        disabled={isLoading}
      />
    </form>
  )
}

export default ConfirmMail
