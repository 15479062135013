import { onLoginViaGoogle, onLoginViaPhone } from "../../actions/auth";

import facebook from '../../assets/img/auth/facebook.png';
import google from '../../assets/img/auth/google.png';
import apple from '../../assets/img/auth/apple.png';
import MobileNumUi from "./MobileNumUi";

function Login({ setStep, moveToOtp, closeModal }) {
  const onSignInSubmit = (phoneNumber, setIsLoading) => {
    const onSuccess = () => moveToOtp(phoneNumber)
    const onError = () => setIsLoading(false)
    onLoginViaPhone(phoneNumber, onSuccess, onError)
  }

  function googleSignIn() {
    const onSuccess = isRegistered => {
      if (!isRegistered) setStep(4)
      else closeModal()
    }

    onLoginViaGoogle(onSuccess)
  }

  return (
    <>
      <MobileNumUi onSubmit={onSignInSubmit} />

      <div className="my-6 relative text-center transform">
        <p className={`h-px absolute inset-x-0 top-1/2 -translate-y-1/2 -z-1 bg-[#13131329]`}></p>
        <p className='inline p-2 text-c-s bg-white text-[#8D8D8D] z-1'>Or</p>
      </div>

      <div className="df gap-4 mb-4">
        <button
          className="dc flex-1 h-12 mr-1 px-8 text-c-s text-primary-600 bg-secondary-100 hover:bg-secondary-200 rounded-lg"
          onClick={googleSignIn}
        >
          <img className="w-6 h-6" src={google} alt="" />
          Sign in with Google
        </button>

        <button className="dc w-[60px] h-12 p-0 bg-[#F6F6F6] hover:bg-[#f6f0f0] rounded-lg">
          <img className="w-6 h-6" src={facebook} alt="" />
        </button>

        <button className="dc w-[60px] h-12 p-0 bg-[#F6F6F6] hover:bg-[#f6f0f0] rounded-lg">
          <img className="w-6 h-6" src={apple} alt="" />
        </button>
      </div>
    </>
  )
}

export default Login
